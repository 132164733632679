<template>
    <div class="user-index">
        <div class="main-h1">Создание дела</div>
        <form class="card" v-on:submit.prevent>
            <div class="card-title">Новое дело</div>
            <div class="card-body">
                <div class="card-body-title">Дело</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Тип *</div>
                            <select v-model="claim.type_id">
                                <option v-for="option in types" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Город *</div>
                            <select v-model="claim.city_id">
                                <option v-for="option in cities" :value="option.id">{{ option.title }}</option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Участники</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Истец</div>
                            <!--
                            <select v-model="claim.claimant_id">
                                <option v-for="option in users.claimants" :value="option.id" :selected="option.id == claim.claimant_id">{{option.name}}</option>
                            </select>
                            -->
                            <Select2 v-model="claim.claimant_id"
                                     :options="users.claimants.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Арбитр</div>
                            <!--
                            <select v-model="claim.arbiter_id">
                                <option v-for="option in users.arbiters" :value="option.id" :selected="option.id == claim.arbiter_id">{{option.name}}</option>
                            </select>
                            -->
                            <Select2 v-model="claim.arbiter_id"
                                     :options="users.arbiters.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Ответчик</div>
                            <!--
                            <select v-model="claim.defendant_id">
                                <option v-for="option in users.defendants" :value="option.id" :selected="option.id == claim.defendant_id">{{option.name}}</option>
                            </select>
                            -->
                            <Select2 v-model="claim.defendant_id"
                                     :options="users.defendants.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Агент</div>
                            <!--
                            <select v-model="claim.agent_id">
                                <option v-for="option in users.agents" :value="option.id" :selected="option.id == claim.agent_id">{{option.name}}</option>
                            </select>
                            -->
                            <Select2 v-model="claim.agent_id"
                                     :options="users.agents.map(item => {item.text = item.name; return item;})"
                                     :settings="{width: '100%'}"/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Стадия</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Стадия</div>
                            <select v-model="claim.stage_id">
                                <option v-for="option in stages" :value="option.id"
                                        :selected="option.id == claim.stage_id">{{ option.title }}
                                </option>
                            </select>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Дедлайн стадии *</div>
                            <input v-model="claim.stage_dl" type="date" required/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Ответственный *</div>
                            <select v-model="claim.implementer_id">
                                <option v-for="option in users.implementers" :value="option.id"
                                        :selected="option.id == claim.implementer_id">{{ option.name }}
                                </option>
                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Суммы</div>
                <div class="row">
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Сумма иска *</div>
                            <input v-model="claim.amount" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Арбитражный сбор *</div>
                            <input v-model="claim.amount_sbor" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Возврат арбитражного сбора</div>
                            <input v-model="claim.amount_sbor_out" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Гонорар арбитра *</div>
                            <input v-model="claim.amount_arbiter" type="number" min="0"/>
                        </label>
                    </div>
                    <div class="col-fg-1">
                        <label class="form-label">
                            <div class="t">Вознаграждение агента</div>
                            <input v-model="claim.amount_agent" type="number" min="0"/>
                        </label>
                    </div>
                </div>
            </div>
            <button :disabled="!btnEnabled" class="btn green" @click="createClaim">Создать</button>
        </form>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';

export default {
    name: "create",
    components: {Select2},
    data() {
        return {
            claim: {
                city_id: 1,
                type_id: 1,
                implementer_id: 0,
                claimant_id: 0,
                arbiter_id: 0,
                defendant_id: 0,
                agent_id: 0,
                stage_id: 1,
                amount: 0,
                amount_sbor: 0,
                amount_sbor_out: 0,
                amount_arbiter: 0,
                amount_agent: 0,
            },
            users: {
                claimants: [],
                arbiters: [],
                agents: [],
                defendants: [],
                implementers: [],
            },
            stages: [],
            cities: [],
            types: [],
        }
    },
    methods: {
        createClaim() {
            this.$api.post(`claim`, this.claim).then(res => {
                if (res.data.success) {
                    this.claim = res.data.data;
                    this.$store.commit('showNotify', {
                        title: 'Сохранено',
                        content: 'Дело успешно создано.',
                        type: 'success'
                    });
                    this.$router.push('/panel/claim');
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getImplementers() {
            this.$api.get(`user/implementers`).then(res => {
                if (res.data.success) {
                    this.users.implementers = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getClaimants() {
            this.$api.get(`user/claimants`).then(res => {
                if (res.data.success) {
                    this.users.claimants = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getArbiters() {
            this.$api.get(`user/arbiters`).then(res => {
                if (res.data.success) {
                    this.users.arbiters = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getDefendants() {
            this.$api.get(`user/defendants`).then(res => {
                if (res.data.success) {
                    this.users.defendants = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getAgents() {
            this.$api.get(`user/agents`).then(res => {
                if (res.data.success) {
                    this.users.agents = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getStages() {
            this.$api.get(`claim/stages`).then(res => {
                if (res.data.success) {
                    this.stages = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getCities() {
            this.$api.get(`city`).then(res => {
                if (res.data.success) {
                    this.cities = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getTypes() {
            this.$api.get(`claim/types`).then(res => {
                if (res.data.success) {
                    this.types = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        relateAgentToClaimant(claimant_id) {
            this.$api.get(`user/${claimant_id}/rel_agent`).then(res => {
                if (res.data.success) {
                    this.claim.agent_id = res.data.data.agent_id;
                }
            })
        },
        fillAmounts() {
            switch (this.claim.type_id) {
                case 1: // Имущественный
                    switch (true) {
                        case this.claim.amount <= 1600000:
                            this.claim.amount_sbor = Math.max(Math.ceil(this.claim.amount * 0.06), 200000);
                            break;
                        case this.claim.amount > 1600000 && this.claim.amount <= 5000000:
                            this.claim.amount_sbor = Math.max(Math.ceil(this.claim.amount * 0.05), 200000);
                            break;
                        case this.claim.amount > 5000000 && this.claim.amount <= 10000000:
                            this.claim.amount_sbor = Math.ceil(this.claim.amount * 0.04);
                            break;
                        case this.claim.amount > 10000000:
                            this.claim.amount_sbor = Math.ceil(this.claim.amount * 0.03);
                            break;
                    }
                    this.claim.amount_arbiter = Math.min(Math.ceil(this.claim.amount_sbor * 0.1), 150000);
                    this.claim.amount_agent = 0;
                    if (this.claim.agent_id > 0) {
                        this.claim.amount_agent = Math.ceil(this.claim.amount_sbor * 0.1);
                    }
                    break;
                case 2: // Неимущественный
                    this.claim.amount = 0;
                    this.claim.amount_sbor = 200000;
                    this.claim.amount_arbiter = 20000;
                    this.claim.amount_agent = 0;
                    if (this.claim.agent_id > 0) {
                        this.claim.amount_agent = 20000;
                    }
                    break;
            }
        }
    },
    created() {
        this.getImplementers();
        this.getClaimants();
        this.getArbiters();
        this.getDefendants();
        this.getStages();
        this.getAgents();
        this.getCities();
        this.getTypes();
    },
    computed: {
        btnEnabled: function () {
            return (this.claim.amount || this.claim.type_id == 2) && this.claim.amount_sbor && this.claim.amount_sbor_out !== '' && this.claim.amount_arbiter && this.claim.stage_dl && this.claim.type_id;
        }
    },
    watch: {
        'claim.claimant_id': function () {
            this.relateAgentToClaimant(this.claim.claimant_id);
        },
        'claim.amount': function () {
            this.fillAmounts();
        },
        'claim.type_id': function () {
            this.fillAmounts();
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../../assets/scss/variables";

.select2-container--default .select2-selection--single {
    border-color: red;
    border-radius: 10px;
}

.amount-sbor-wrap {
    display: flex;
    gap:10px;
}
</style>